import React, { forwardRef } from 'react';
import { ChevronRightBold } from '@openameba/spindle-ui/Icon';

import * as style from './TextLinkButton.module.css';
import { Link } from 'gatsby';

interface Props {
  to: string;
  children?: React.ReactNode;
}

const TextLinkButton: React.FC<Props> = ({ children, to = '' }) => {
  return (
    <Link className={style.root} to={to}>
      <span className={style.label}>{children}</span>
      <ChevronRightBold className={style.icon} aria-hidden="true" />
    </Link>
  );
};

export default TextLinkButton;
