import React, { useEffect, useState, useRef } from 'react';
import { PageProps } from 'gatsby';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../components/Home';
import SEO from '../components/SEO';

import 'normalize.css';
import 'ameba-color-palette.css';
import '../components/layout.css';

export default function ({ location }: PageProps) {
  const [outView, setOutView] = useState(false);
  const target = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!target.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) =>
      setOutView(entry.intersectionRatio < 0.1),
    );
    observer.observe(target.current);

    return () => {
      observer.disconnect();
    };
  }, [target, setOutView]);

  return (
    <>
      <SEO />
      <header
        className={
          outView ? 'layout-header' : 'layout-header layout-header-home'
        }
      >
        <div className="layout-header-inner">
          <Header pathname={location.pathname} />
        </div>
      </header>

      <div className="layout-virtuality-element" ref={target} />
      <Home />

      <footer className="layout-footer">
        <Footer />
      </footer>
    </>
  );
}
